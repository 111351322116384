import React from 'react';
import styles from './Not-Authorized.module.css'

const NotAuthorized: React.FC = () => {
  return (
    <div className={styles.auth}>
      <h1>Not Authorized</h1>
      <p>You do not have permission to view this page.</p>
    </div>
  );
};

export default NotAuthorized;