import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react';

import Chat from './pages/chat/Chat';
import NotAuthorized from './pages/not-authorized/Not-Authorized';
import Layout from './pages/layout/Layout';
import NoPage from './pages/NoPage';
import { AppStateProvider } from './state/AppProvider';

import './index.css';
import Login from './pages/login/login';

initializeIcons();

const isAuthenticated = () => {
  const token = sessionStorage.getItem('access_token');
  if (!token) {
    return false;
  }

  const payloadBase64 = token.split('.')[1];
  const payloadJson = atob(payloadBase64);
  const payload = JSON.parse(payloadJson);

  const expirationTime = payload.exp * 1000;
  const currentTime = Date.now();

  return currentTime < expirationTime;
};

const AuthGuard = ({ children }: { children: JSX.Element }) => {
  return isAuthenticated() ? children : <Navigate to="/not-authorized" />;
};

function App() {
  return (
    <AppStateProvider>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/not-authorized" element={<NotAuthorized />} />
            <Route
              index
              element={
                <AuthGuard>
                  <Chat />
                </AuthGuard>
              }
            />
            <Route
              path="*"
              element={
                <AuthGuard>
                  <NoPage />
                </AuthGuard>
              }
            />
          </Route>
        </Routes>
      </HashRouter>
    </AppStateProvider>
  );
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
