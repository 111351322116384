import { useNavigate } from 'react-router-dom';
import styles from './login.module.css'

const Login = () => {
    const navigate = useNavigate(); // Initialize navigate
    window.addEventListener('message', (event)=>{
        if(event && event.data && event.data.access_token){
            let tokenWatcher = setInterval(()=>{
              if(sessionStorage.getItem('access_token')){
                clearInterval(tokenWatcher);
                window.location.href = '/';
              } 
            }, 500);
            sessionStorage.setItem('access_token', event.data.access_token); 
        }
    });

  return (
    <div className={styles.login}>
    <h1>One moment, checking if you have the required permissions.</h1>
  </div>
  );
};

export default Login;
